<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
    </div>
    <div class="chapter">
      <div style="font-size: 28px; font-weight: 800; margin-bottom: 40px">
        IADGE分会细则
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        尊敬的IADGE会员{{ $store.state.userinfo.username }}，
      </div>
      <div style="font-size: 16px; margin-bottom: 20px">
        您已于2016年10月3日获得IADGE分会首席运营官的特许,以下是您将作为IADGE分会运营者的章程:
      </div>
      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第一条：名称和范围
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        该组织名称为：{{this.$store.state.branch}}
      </div>
      <div style="font-size: 16px; margin-bottom: 20px">
        本分会的范围是为数字游戏专业技术技能人才的培养提供高质量的成长路径，积极推动国际交流。
      </div>
      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第二条：宗旨
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、本分会的组织和运作完全是以培养适合社会需求的数字游戏产业技术技能人才为目标
        ，以促进以下内容：
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        （1）对数字游戏专业技术的兴趣增加
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        （2）对数字游戏及其技术应用更感兴趣
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        （3）一种与对数字游戏感兴趣的人之间的交流方式
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、本分会将为IADGE分会{{ $store.state.userinfo.username }}的会员和社区中的其他感兴趣的人提供服务。
      </div>
      <div style="font-size: 16px; margin-bottom: 20px">
        3、本分会由WorldSkills
        3D数字游戏艺术项目中国首席专家联合上海国际计算机图形图像学会（ACM
        SIGGRAPH）发起成立。
      </div>
      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第三条：会员
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、本分会的会员资格应向所有IADGE会员开放。
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、分会有表决权的会员资格应授予所有活跃的分会会员。
      </div>

      <div style="font-size: 16px; margin-bottom: 20px">
        3、会员资格不受种族、信仰、年龄、性别、残疾、国籍、性取向、经济状况、退伍军人身份和父母身份的限制。
      </div>

      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第四条：干事
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、本分会的干事应为：主任、副主任、秘书长和财务。这些人必须是IADGE专业会员，也可以设立其他会员。
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、干事团会员应在年度选举会议上以投票多数选出，或通过电子投票选出，任期至少一年。
      </div>

      <div style="font-size: 16px; margin-bottom: 20px">
        3、任命或提名个人担任IADGE分会的干事职位通常应限制在同一职位上连续两届。本建议旨在鼓励新会员继续融入组织，并鼓励干事考虑为其继任者进行规划。
      </div>
      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第五条：高级会员的职责
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、主任是首席会员，负责领导分会并根据IADGE的政策和程序以及本细则管理其活动。
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、副主任应在主任缺席的情况下主持会议，协助主任管理分会，并履行主任可能指派的其他职责。
      </div>

      <div style="font-size: 16px; margin-bottom: 12px">
        3、秘书长应保存本分会所有会议的记录。秘书长的其他职责包括：
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        （1）维护本分会的记录
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">（2）协调选举</div>

      <div style="font-size: 16px; margin-bottom: 12px">
        （3）履行主席指派的其他职责
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        （4）对这些章程的任何拟议修订必须提交给IADGE总部。提议的修正案必须获得批准，然后才能提交给分会成员进行投票
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        4、财务是本分会的首席财务官。财务的职责包括：
      </div>

      <div style="font-size: 16px; margin-bottom: 12px">
        （1）收取会费，支付所有账单，并为本分会保存记录
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        （2）编制分会的年度报告和干事联系信息，并通过分会管理界面将其提交给IADGE总部
      </div>
      <div style="font-size: 16px; margin-bottom: 20px">
        （3）履行主任指定的其他职责
      </div>
      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第六条：会议
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、分会应仅在对协会所有会员开放的地方举行会议。分会每年至少为普通会员举行3次会议。
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、应举行年度选举会议。在该会议上，财务将提交所需的报告。此外，还应举行干事选举。
      </div>

      <div style="font-size: 16px; margin-bottom: 20px">
        3、任命或提名个人担任IADGE分会的干事职位通常应限制在同一职位上连续两届。本建议旨在鼓励新会员继续融入组织，并鼓励干事考虑为其继任者进行规划。
      </div>

      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第七条：支出和应付款项
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、财政部对分会的支出应由分会的任何在职干事支付，并应列入其会议记录。
      </div>
      <div style="font-size: 16px; margin-bottom: 20px">
        2、应固定每年费用。
      </div>

      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第八条：修正和表决程序
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、对本分会细则的所有拟议修改应在提交给分会会员投票之前，由IADGE总部批准。
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、除非达到法定人数，否则不得处理本分会的任何公务。本分会的法定人数应定义为本分会有表决权的会员的过半数。
      </div>
      <div style="font-size: 16px; margin-bottom: 20px">
        3、干事将由多票选举产生。
      </div>

      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第九条：行为准则
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、不受欢迎的骚扰或敌对行为，包括恐吓、制造不适或干扰他人参加分会会议或分会活动的言论。任何形式的骚扰，包括但不限于基于外侨或公民身份、年龄、肤色、信仰、残疾、婚姻状况、军人身份、国籍、怀孕、，分娩和怀孕相关的医疗条件、种族、宗教。性别、退伍军人身份、性取向或任何其他身份受到分会会议或分会活动所在法律保护的骚扰是不可容忍的。骚扰包括使用辱骂或有辱人格的语言、恐吓、跟踪、骚扰性摄影或录音、不适当的身体接触、，性意象和不受欢迎的性关注。参与者“只是在开玩笑”、“喝茶”或“开玩笑”的回答将不被接受。
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、任何目睹或遭受不可接受行为的人都应通知分会干事或IADGE总部。
      </div>
      <div style="font-size: 16px; margin-bottom: 20px">
        3、违反这些标准的个人可根据分会干事的决定受到制裁或被排除在进一步参与之外。
      </div>

      <div style="font-size: 16px; font-weight: 800; margin-bottom: 12px">
        第十条：分会的解散
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        1、经成员同意解散本分会，应包括全体会员一致同意，并在事先向本分会所有会员公布的会议上进行多数表决。
      </div>
      <div style="font-size: 16px; margin-bottom: 12px">
        2、如果本分会解散，其资产和负债应转移给IADGE，并由IADGE的财务总监监督。
      </div>
    </div>
  </div>
</template>
  
  
  <script>
export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
  },
  components: {},
};
</script>
  <style scoped lang="scss">
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  //   align-items: center;
  position: relative;
  .chapter {
    color: #333;
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
  <style lang="scss">
</style>
  